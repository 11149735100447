import { keys } from "./keys";

const LOCAL_STORAGE = globalThis.localStorage || null;
const methods = () => {
  return {
    // Local storage
    get: (key: string) => LOCAL_STORAGE?.getItem(key),
    set: (key: string, value: string) => LOCAL_STORAGE?.setItem(key, value),
    remove: (key: string) => LOCAL_STORAGE?.removeItem(key),
    // Signup refferer URL
    getSignupReferrerUrl: () => LOCAL_STORAGE?.getItem(keys.SIGNUP_REFERRER_URL),
    setSignupReferrerUrl: (url: string) => LOCAL_STORAGE?.setItem(keys.SIGNUP_REFERRER_URL, url),
    removeSignupReferrerUrl: () => LOCAL_STORAGE?.removeItem(keys.SIGNUP_REFERRER_URL),
    // Tutorial
    getIsPromptTutorialDone: () => LOCAL_STORAGE?.getItem(keys.USER_EXPERIENCED_PROMPT_TUTORIAL) === "true",
    setIsPromptTutorialDone: () => LOCAL_STORAGE?.setItem(keys.USER_EXPERIENCED_PROMPT_TUTORIAL, "true"),
    // Magic link
    getLastUsedMagicLinkEmail: () => LOCAL_STORAGE?.getItem(keys.LAST_USED_MAGIC_LINK_EMAIL),
    setLastUsedMagicLinkEmail: (email: string) => LOCAL_STORAGE?.setItem(keys.LAST_USED_MAGIC_LINK_EMAIL, email),
    removeLastUsedMagicLinkEmail: () => LOCAL_STORAGE?.removeItem(keys.LAST_USED_MAGIC_LINK_EMAIL),
    // Homepage prompt param
    getDateLastUsedPromptParam: () => Number(LOCAL_STORAGE?.getItem(keys.DATE_LAST_USED_PROMPT_PARAM)),
    setDateLastUsedPromptParam: (epoch: number) => LOCAL_STORAGE?.setItem(keys.DATE_LAST_USED_PROMPT_PARAM, `${epoch}`),
    // Feedback email
    getFeedbackEmail: () => LOCAL_STORAGE?.getItem(keys.FEEDBACK_EMAIL),
    setFeedbackEmail: (email: string) => LOCAL_STORAGE?.setItem(keys.FEEDBACK_EMAIL, email),
    // Feedback email
    getLastCollectionsLHSTab: () => LOCAL_STORAGE?.getItem(keys.COLLECTIONS_LEFT_SIDE),
    setLastCollectionsLHSTab: (tab: string) => LOCAL_STORAGE?.setItem(keys.COLLECTIONS_LEFT_SIDE, tab),
    // Collection invite
    getCollectionInvite: () => LOCAL_STORAGE?.getItem(keys.COLLECTION_INVITE),
    setCollectionInvite: (invite: string) => LOCAL_STORAGE?.setItem(keys.COLLECTION_INVITE, invite),
  };
};

/**
 * Browser storage utility.
 */
export const BrowserStorage = methods();
